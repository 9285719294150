
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';

import NotificationBanner from '@/components/blocks/notification-banner.vue';
import FormInput from '@/components/controls/form-input.vue';
import ImageUploadControl from '@/components/controls/image-upload.vue';
import Publish from '@/components/forms/publish.vue';
import ProductIsFeaturedForm from '@/components/forms/product-is-featured.vue';
import CountriesSelection from '@/components/controls/countries-selection.vue';
import TranslationsControl from '@/components/controls/translations.vue';

import { EditableStatus } from '@/store/types';
import { ProductsActions } from '@/store/modules/products/types';
import Autocomplete, { AutocompleteOption } from '@/components/controls/autocomplete.vue';
import { ProductStatus } from '@/store/modules/products/product';
import Taxonomy from '@/store/models/taxonomy';

export default Vue.extend({
  name: 'EditProductView',
  inject: {
    $validator: '$validator',
  },
  components: {
    FormInput,
    NotificationBanner,
    Publish,
    Autocomplete,
    ImageUploadControl,
    ProductIsFeaturedForm,
    CountriesSelection,
    TranslationsControl,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accessoriesInput: '',
      treatmentsInput: '',
    };
  },
  computed: {
    /**
     * Products
     */
    ...mapState('products', {
      product: 'editable',
      editableStatus: 'editableStatus',
      translationsStatus: 'translationsStatus',
      accessories: 'accessoriesTree',
    }),
    ...mapGetters('products', {
      isLoading: 'editableIsLoading',
      isSaving: 'editableIsSaving',
      hasUnsavedChanges: 'editableHasUnsavedChanges',
    }),

    /**
     * Treatments
     */
    ...mapState('treatments', {
      treatments: 'allTreatments',
      publicSiteList: 'publicSiteList',
      pagination: 'publicSiteListPagination',
    }),

    ...mapGetters('treatments', {
      publicSiteListIsLoading: 'publicSiteListIsLoading',
      publicSiteListIsLoaded: 'publicSiteListIsLoaded',
      publicSiteListIsEmpty: 'publicSiteListIsEmpty',
    }),

    getAccessories(): Taxonomy[] {
      const ids = this.product.accessories.map(({ id }) => id);
      return this.accessories.filter(({ id }) => ids.indexOf(id) === -1);
    },

    getAccessoriesOptions(): AutocompleteOption[] {
      return this.getAccessories
        .map((accessory) => ({
          label: accessory.title,
          value: accessory.id,
        }))
        .filter(({ label }) => label.toLowerCase().indexOf(this.accessoriesInput.toLowerCase()) > -1);
    },

    getSiteOptions(): AutocompleteOption[] {
      return this.publicSiteList.map((site) => ({
        label: site.title,
        value: site.id,
      }));
    },

    getTreatments(): AutocompleteOption[] {
      const ids = this.product.treatments.map(({ id }) => id);
      return this.treatments
        .filter(({ id }) => ids.indexOf(id) === -1)
        .map(({ id, title }) => ({
          label: title,
          value: id,
        }))
        .filter(({ label }) => label.toLowerCase().indexOf(this.treatmentsInput.toLowerCase()) > -1);
    },
    accessoryTreatments(): Taxonomy[] {
      const accessoryIds = this.product.accessories.map(({ id }) => id);
      const initial: { [key: number]: Taxonomy } = {};

      if (!accessoryIds.length) {
        return [];
      }

      const accessories = this.accessories.filter(({ id }) => accessoryIds.indexOf(id) !== -1);

      return values(
        accessories
          .map(({ treatments }) => treatments)
          .reduce((res, treatments) => {
            treatments.forEach((treatment) => {
              res[treatment.id] = treatment;
            });

            return res;
          }, initial),
      );
    },
    selectedTreatments(): Taxonomy[] {
      const ids = this.product.treatments.map(({ id }) => id);

      return this.treatments.filter(({ id }) => ids.indexOf(id) !== -1);
    },
    productTreatments(): Taxonomy[] {
      const treatments: { [key: number]: Taxonomy } = {};

      this.selectedTreatments.concat(this.accessoryTreatments).forEach((treatment) => {
        treatments[treatment.id] = treatment;
      });

      return sortBy(values(treatments), 'title');
    },

    /**
     * Component
     */
    isFetching() {
      return this.isLoading || this.isSaving;
    },
    isInactive() {
      return this.product.status === ProductStatus.Inactive;
    },
  },
  methods: {
    ...mapActions('products', {
      updateProduct: ProductsActions.UPDATE_EDITABLE,
      save: ProductsActions.SAVE_EDITABLE,
      removeProduct: ProductsActions.DELETE,
      restoreProduct: ProductsActions.RESTORE,
      saveTranslations: ProductsActions.SAVE_TRANSLATIONS,
    }),
    addAccessory(option: AutocompleteOption) {
      const accessories = [...this.product.accessories];
      const accessory = this.accessories.find((item) => item.id === option.value);
      if (!accessory) {
        return;
      }

      accessories.push(accessory);

      this.updateProduct({ accessories });
    },
    removeAccessory(accessoryId: number) {
      const accessories = this.product.accessories.filter(({ id }) => id !== accessoryId);

      this.updateProduct({ accessories });
    },

    addTreatment(option: AutocompleteOption) {
      const treatments = [...this.product.treatments];
      const treatment = this.treatments.find((item) => item.id === option.value);
      if (!treatment) {
        return;
      }

      treatments.push(Taxonomy.fromJSON(treatment.toJSON()));

      this.updateProduct({ treatments });
    },
    removeTreatment(treatmentId: number) {
      const treatments = this.product.treatments.filter(({ id }) => id !== treatmentId);

      this.updateProduct({ treatments });
    },
    addSite(option: AutocompleteOption) {
      const sites = [...this.product.sites];
      const site = this.publicSiteList.find((item) => item.id === option.value);
      if (!site) {
        return;
      }
      sites.push(Taxonomy.fromJSON(site.toJSON()));

      this.updateProduct({ sites });
    },
    removeSite(siteId: number) {
      const sites = this.product.sites.filter(({ id }) => id !== siteId);

      this.updateProduct({ sites });
    },
    remove() {
      this.removeProduct({ id: this.product.id });
    },
    restore() {
      this.restoreProduct({ id: this.product.id });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));

      if (!answer) {
        next(false);
        return;
      }
    }

    next();
  },
});


import Vue, { PropOptions } from 'vue';
import i18n, { parseToDate } from '@/i18n';
import Event from '@/store/modules/events/event';

const labels = {
  'aesthetic-exchange': 'aesthetic',
  'cynosure-academy': 'dark',
  'womens-health-events': 'monalisa',
};

let dayDateFormatterLocale = i18n.language;
const dayDateFormatterOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  formatMatcher: 'basic',
  timeZone: 'Europe/London',
};
let dayDateFormatter = new Intl.DateTimeFormat(dayDateFormatterLocale, dayDateFormatterOptions);

const formatDay = (date: Date): string => {
  if (dayDateFormatterLocale !== i18n.language) {
    dayDateFormatter = new Intl.DateTimeFormat(i18n.language, dayDateFormatterOptions);
    dayDateFormatterLocale = i18n.language;
  }

  let d = date;

  if (!(date instanceof Date)) {
    const parsed = parseToDate(date);

    if (!(parsed instanceof Date)) {
      return parsed;
    }

    d = parsed;
  }

  return dayDateFormatter.format(d);
};

let customDateFormatterLocale = i18n.language;
const customDateFormatterOptions: Intl.DateTimeFormatOptions = {
  month: 'long',
  year: 'numeric',
  formatMatcher: 'basic',
  timeZone: 'Europe/London',
};
let customDateFormatter = new Intl.DateTimeFormat(customDateFormatterLocale, customDateFormatterOptions);

const formatCustomDate = (date: Date): string => {
  if (customDateFormatterLocale !== i18n.language) {
    customDateFormatter = new Intl.DateTimeFormat(i18n.language, customDateFormatterOptions);
    customDateFormatterLocale = i18n.language;
  }

  let d = date;

  if (!(date instanceof Date)) {
    const parsed = parseToDate(date);

    if (!(parsed instanceof Date)) {
      return parsed;
    }

    d = parsed;
  }

  return customDateFormatter.format(d);
};

export default Vue.extend({
  props: {
    event: {
      type: Object,
      required: true,
    } as PropOptions<Event>,
  },
  computed: {
    eventTitle(): string {
      return [this.event.street, this.event.city, this.event.provinceName, this.event.countryName]
        .filter(Boolean)
        .map((item) => String(item).trim())
        .filter(Boolean)
        .join(', ');
    },
    label() {
      if (!this.event.type || !labels[this.event.type.slug]) {
        return 'primary';
      }

      return labels[this.event.type.slug];
    },
    eventStartDay() {
      return formatDay(this.event.start);
    },
    eventStartDate() {
      return formatCustomDate(this.event.start);
    },
  },
  methods: {
    formatDay,
    formatDate: formatCustomDate,
  },
});

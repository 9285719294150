import { PluginFunction } from 'vue';
import VueRouter, { Route } from 'vue-router';
import { Omit } from 'lodash';

import { getURLObject } from '@/utils/url';
import { getRandomInt } from '@/utils/random';
import { EventTypes, ApplicationEvent, Priority } from '@/store/types';
import ResourceFile from '@/store/modules/resources/file';
import store from '@/store';

import {
  GTMEventData,
  PageViewData,
  DownloadData,
  WebstoreClickData,
  VideoPlayEventData,
  VideoPlayData,
  ResourceSearchData,
  UserLogoutData,
  UserLoginData,
  SessionStartData,
  UserData,
  ProductData,
  PersonalizeData,
} from './types';
import { Resource } from '@/store/modules/resources/resource';
import { Profile } from '@/store/modules/user/profile/profile';

const GTM_EVENT_FIRE_TIMEOUT = 500;
const GTM_CONTAINER_ID = 'GTM-5Z8N2X6';

let installed = false;
let isLoaded = false;
let isLoading = false;
let router: VueRouter;

/**
 * Maybe load the script
 */
const loadScript = (): void => {
  // Check if the script is already loaded or is in process of loading
  if (isLoaded || isLoading) {
    return;
  }

  isLoading = true;

  (window as any).dataLayer = (window as any).dataLayer || [];

  (window as any).dataLayer.push({
    'gtm.start': new Date().getTime(),
    'event': 'gtm.js',
  });

  const gtmScript = document.createElement('script');
  const scriptUrl = 'https://www.googletagmanager.com/gtm.js?id=' + GTM_CONTAINER_ID;

  gtmScript.setAttribute('src', scriptUrl);
  gtmScript.setAttribute('type', 'text/javascript');
  gtmScript.setAttribute('async', 'true');

  gtmScript.onload = () => {
    isLoaded = true;
    isLoading = false;
  };

  gtmScript.onerror = () => {
    gtmScript.remove();
    isLoading = false;
  };

  document.body.appendChild(gtmScript);
};

const webstoreClick = () => {
  const data: WebstoreClickData = {
    ...pageData(),
    event: 'webstore_click',
  };

  return fire(data);
};

const pageChange = (to: Route): void => {
  const url = getURLObject(window.location.href);

  const data: PageViewData = {
    ...pageData(),
    event: 'page_view',
    page_title: to.name ? to.name : to.path,
    page_url: url.toString(),
    page_urlscheme: url.protocol.replace(/:$/, ''),
    page_urlhost: url.hostname,
  };

  fire(data);
};

const login = (): void => {
  const data: UserLoginData = {
    ...pageData(),
    ...userData(),
    event: 'user_login',
  };

  fire(data);
};

const session = (): void => {
  const data: SessionStartData = {
    ...pageData(),
    ...userData(),
    event: 'session_start',
  };

  fire(data);
};

const logout = (): void => {
  const data: UserLogoutData = {
    ...pageData(),
    event: 'user_logout',
  };
  fire(data);
};

const resourceSearch = (filters: any): void => {
  if (filters.search) {
    const data: ResourceSearchData = {
      ...pageData(),
      event: 'resource_search',
      search_query: filters.search,
    };
    fire(data);
  }
};

const downloadAll = (resource: Resource): void => {
  const data: DownloadData = {
    ...pageData(),
    event: 'download',
    treatment: resource.products && resource.products.length > 0 ? resource.products[0].title : 'no-product',
    category: resource.categories && resource.categories.length > 0 ? resource.categories[0].title : 'no-category',
    download_name: resource.title + ' (All Files)',
    page_title: resource.title,
  };
  fire(data);
};

const download = (resource: Resource, file: ResourceFile): void => {
  const data: DownloadData = {
    ...pageData(),
    event: 'download',
    treatment: resource.products && resource.products.length > 0 ? resource.products[0].title : 'no-product',
    category: resource.categories && resource.categories.length > 0 ? resource.categories[0].title : 'no-category',
    download_name: file.name ? file.name : 'unnamed file',
    page_title: resource.title,
  };

  fire(data);
};

const personalize = (product: string, assetType: string): void => {
  const data: PersonalizeData = {
    ...pageData(),
    ...userData(),
    event: 'personalize_resource',
    product,
    asset_type: assetType,
  };

  fire(data);
};

const videoPlay = (playData: VideoPlayData) => {
  const data: VideoPlayEventData = {
    ...pageData(),
    ...playData,
    event: 'video_play',
  };

  fire(data);
};

const pageData = () => {
  const currentRoute = router.currentRoute;
  const url = getURLObject(window.location.origin + currentRoute.fullPath);

  const data: Omit<GTMEventData, 'event'> = {
    page_title: currentRoute.name ? currentRoute.name : currentRoute.path,
    page_urlscheme: url.protocol.replace(/:$/, ''),
    page_url: url.toString(),
    page_urlhost: url.hostname,
  };

  return data;
};

const userData = (): UserData => {
  const profile: Profile = store.getters['user/profile/currentProfile'];
  const isSuperAdmin: boolean = store.getters['user/profile/isSuperAdmin'];
  const hasPractice: boolean = store.getters['user/profile/hasPractice'];
  const agreeUpgrade: boolean = store.getters['user/profile/agreeUpgrade'];
  let practiceHasSculpsure = false;
  let practicePriority = Priority.gold;
  let productOwned: ProductData[] = [];
  let additionalProducts: ProductData[] = [];
  let adProduct: string | undefined;
  let practiceCountry = '';

  if (hasPractice && profile.practice) {
    if (profile.practice.hasSculpsure) {
      practiceHasSculpsure = profile.practice.hasSculpsure;
    }

    if (profile.practice.priority) {
      practicePriority = profile.practice.priority;
    }

    if (profile.practice.countries.length) {
      practiceCountry = profile.practice.countries.map((country) => country.code).join(',');
    }

    if (profile.products) {
      productOwned = profile.products
        .filter((product) => product.owned)
        .map((product) => ({
          amps_id: product.id,
          name: product.title,
        }));

      additionalProducts = profile.products
        .filter((product) => !product.owned)
        .map((product) => ({
          amps_id: product.id,
          name: product.title,
        }));
    }

    if (profile.practice.ads) {
      const ads = Object.keys(profile.practice.ads).map((key) => profile.practice!.ads![key]);

      if (ads.length) {
        const randomAdIndex = getRandomInt(0, ads.length - 1);

        adProduct = ads[randomAdIndex];
      }
    }
  }

  return {
    is_admin: isSuperAdmin,
    customer_email: profile.email,
    customer_first_name: profile.firstName,
    customer_id: profile.id,
    customer_last_name: profile.lastName,
    language_code: profile.languageCode,
    country_code: practiceCountry,
    practice_has_sculpsure: practiceHasSculpsure,
    practice_priority: practicePriority,
    product_owned: productOwned,
    additional_products: additionalProducts,
    ad_product: adProduct,
    agreed_to_upgrade: agreeUpgrade,
  };
};

const fire = (data: GTMEventData): Promise<void> => {
  return new Promise((resolve) => {
    (window as any).dataLayer = (window as any).dataLayer || [];

    const timeoutId = setTimeout(() => resolve(), GTM_EVENT_FIRE_TIMEOUT);
    const cb = () => {
      clearTimeout(timeoutId);

      resolve();
    };

    (window as any).dataLayer.push({
      ...data,
      eventCallback: (gtmContainerID: string) => {
        if (gtmContainerID === GTM_CONTAINER_ID) {
          cb();
        }
      },
    });
  });
};

const processEvent = (event: ApplicationEvent): void => {
  switch (event.type) {
    case EventTypes.PAGE_CHANGE:
      pageChange(event.eventObj.to);
      break;
    case EventTypes.DOWNLOAD:
      download(event.eventObj.resource, event.eventObj.file);
      break;
    case EventTypes.DOWNLOAD_ALL:
      downloadAll(event.eventObj.resource);
      break;
    case EventTypes.LOGIN:
      login();
      break;
    case EventTypes.SESSION:
      session();
      break;
    case EventTypes.LOGOUT:
      logout();
      break;
    case EventTypes.SEARCH:
      resourceSearch(event.eventObj.filters);
      break;
    case EventTypes.VIDEO_PLAY:
      videoPlay(event.eventObj);
      break;
    case EventTypes.PERSONALIZE:
      personalize(event.eventObj.product, event.eventObj.asset_type);
      break;
  }
};

const gtmPlugin: PluginFunction<{ router: VueRouter }> = (vue, options) => {
  // Make sure the plugin is only installed once
  if (installed) {
    return;
  }

  if (!options) {
    throw new Error('You must provide router to GTM plugin.');
  }

  installed = true;
  router = options.router;

  vue.trackOutbound.addLoadingListener(({ link }) => {
    if (link.indexOf('store.cynosure.com') === -1) {
      return Promise.resolve();
    }

    return webstoreClick();
  });

  // Watch for global app events
  store.watch(
    (state) => state.appEvents,
    async (queue) => {
      const event = queue[queue.length - 1]; // get last event
      loadScript(); // make sure the GTM script is loaded

      if (event) {
        processEvent(event); // collect data and fire off a appropriate GTM method
      }
    },
  );
};

export default gtmPlugin;

import { ActionTree } from 'vuex';
import { AxiosResponse, AxiosError } from 'axios';

import i18next from '@/i18n';
import router from '@/router';
import api from '@/api';

import createSlug from '@/utils/slug';

import {
  RootState,
  EventTypes,
  RootActions,
  EditableStatus,
  ErrorMessages,
  PublishStatus,
  ListStatus,
} from '@/store/types';
import {
  ResourcesState,
  ResourcesActions,
  ResourcesMutations,
  RECENTLY_VIEWED_STORAGE_KEY_PREFIX,
  ResourcesStatus,
  ResourcesRequestFilters,
} from '@/store/modules/resources/types';
import { NotificationsActions } from '@/store/modules/notifications/types';
import Product from '@/store/modules/products/product';

import Pagination from '@/store/models/pagination';
import { Resource } from './resource';
import { enhanceErrorWithMessage } from '@/utils/errors';

export const actions: ActionTree<ResourcesState, RootState> = {
  /**
   * RESOURCES LOADING
   */
  [ResourcesActions.UPDATE_RESOURCES_FILTERS]({ commit }, filters: ResourcesRequestFilters): Promise<void> {
    commit(ResourcesMutations.SET_RESOURCES_FILTERS, filters);

    return Promise.resolve();
  },

  [ResourcesActions.LOAD]({ dispatch, commit, rootState }, filters: ResourcesRequestFilters = {}): Promise<void> {
    commit(ResourcesMutations.LIST_LOADING);

    return Promise.all([
      api.assets.list(filters).then((response) => {
        commit(ResourcesMutations.CLEAR_RESOURCES);
        commit(ResourcesMutations.SET_RESOURCES, {
          data: response.data.data.map((item) => Resource.fromJSON(item)),
          featured: response.data.featured?.map((item) => Resource.fromJSON(item)) ?? [],
          categories: response.data.categories ?? [],
        });
        commit(ResourcesMutations.SET_PAGINATION, Pagination.fromJSON(response.data));
      }),
      filters.page &&
      filters.page === 1 &&
      filters.products &&
      !filters.products.length &&
      filters.categories &&
      !filters.categories.length &&
      filters.treatments &&
      !filters.treatments.length &&
      (!filters.status || Number(filters.status) !== 0)
        ? dispatch(ResourcesActions.LOAD_LASER_HIGHLIGHTED_RESOURCES, {
            country_id: filters.country_id,
            language_code: filters.language_code,
          })
        : Promise.resolve(),
      filters.page &&
      filters.page === 1 &&
      filters.categories &&
      filters.categories.includes(9) &&
      (!filters.status || Number(filters.status) !== 0)
        ? dispatch(ResourcesActions.LOAD_TRAINING_RESOURCES, {
            country_id: filters.country_id,
            language_code: filters.language_code,
          })
        : Promise.resolve(),
    ])
      .then(() => {
        commit(ResourcesMutations.LIST_LOADED);
      })
      .then(() => {
        const { products } = filters;
        let productsNames: string[] = [];

        if (products && products.length) {
          productsNames = products
            .map((id) =>
              ((rootState as any).treatments.treatmentsTree as Product[]).find((product) => product.id === id),
            )
            .filter(Boolean)
            .map((product) => product!.title);
        }

        return dispatch(
          RootActions.DISPATCH_EVENT,
          {
            type: EventTypes.SEARCH,
            eventObj: {
              filters: {
                ...filters,
                productsNames,
              },
            },
          },
          { root: true },
        );
      })
      .catch((error: AxiosError) => {
        return dispatch(ResourcesActions.RAISE_LIST_ERROR, error);
      });
  },

  [ResourcesActions.LOAD_FEATURED_RESOURCES](
    { commit, dispatch },
    filters: ResourcesRequestFilters = {},
  ): Promise<void> {
    const { products = [], type, language_code } = filters;

    commit(ResourcesMutations.SET_FEATURED_RESOURCES_STATUS, ResourcesStatus.Loading);

    return api.assets
      .listFeatured({ products, type, language_code })
      .then((response) => {
        commit(
          ResourcesMutations.SET_FEATURED_RESOURCES,
          response.data.map((item) => Resource.fromJSON(item)),
        );
        commit(ResourcesMutations.SET_FEATURED_RESOURCES_STATUS, ResourcesStatus.Loaded);
      })
      .catch((error) => {
        commit(ResourcesMutations.SET_FEATURED_RESOURCES_STATUS, ResourcesStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [ResourcesActions.LOAD_RESOURCE]({ commit, dispatch }, { id, isDirect }): Promise<void | AxiosError> {
    commit(ResourcesMutations.CURRENT_LOADING);
    commit(ResourcesMutations.SET_IS_RESOURCE_DIRECTLY_ACCESSED, isDirect);

    return api.assets
      .get(id)
      .then((response) => {
        commit(ResourcesMutations.SET_CURRENT_RESOURCE, Resource.fromJSON(response.data));
        commit(ResourcesMutations.CURRENT_LOADED);
        dispatch(ResourcesActions.REGISTER_RESOURCE_VIEW);
      })
      .catch((error: AxiosError) => {
        return dispatch(ResourcesActions.RAISE_CURRENT_ERROR, error);
      });
  },

  [ResourcesActions.LOAD_EDITABLE]({ commit, dispatch }, { id }): Promise<void> {
    commit(ResourcesMutations.SET_EDITABLE_STATUS, EditableStatus.Loading);

    return api.assets
      .get(id)
      .then((response) => {
        const resource = Resource.fromJSON(response.data);

        commit(ResourcesMutations.SET_EDITABLE, resource);
        commit(ResourcesMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ResourcesMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [ResourcesActions.LOAD_EMPTY_EDITABLE]({ commit }): Promise<void> {
    commit(ResourcesMutations.SET_EDITABLE_STATUS, EditableStatus.Loading);
    commit(ResourcesMutations.SET_EDITABLE, new Resource());
    commit(ResourcesMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);

    return Promise.resolve();
  },

  [ResourcesActions.UPDATE_EDITABLE]({ commit }, changes): Promise<void> {
    commit(ResourcesMutations.UPDATE_EDITABLE, changes);
    return Promise.resolve();
  },

  [ResourcesActions.RESTORE_EDITABLE]({ dispatch }): Promise<void> {
    return dispatch(ResourcesActions.SAVE_EDITABLE);
  },

  [ResourcesActions.SAVE_EDITABLE]({ state, commit, dispatch }): Promise<void> {
    let isUpdate: boolean;
    let successMsg: string;

    commit(ResourcesMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

    const routeName = (router.currentRoute.name == 'clinical-support-add-resource' || router.currentRoute.name == 'clinical-support-edit-resource' ) ? 'clinical-support' : '';

    return new Promise<void>((resolve, reject): PromiseLike<AxiosResponse> | void => {
      if (!state.editable) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => {
        const preparedResource = state.editable!.toJSON();
        preparedResource.status = PublishStatus.Published;
        preparedResource.type = routeName;

        isUpdate = Boolean(preparedResource.id);
        successMsg = i18next.t('A new resource was added successfully.');

        if (isUpdate) {
          successMsg = i18next.t('The resource was successfully updated.');
        }

        return api.assets.store(preparedResource);
      })
      .then((response): Promise<Resource> => {
        const resource: Resource = Resource.fromJSON(response.data);
        commit(ResourcesMutations.SET_EDITABLE, resource);
        commit(ResourcesMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);

        if (!isUpdate) {
          return new Promise((resolve, reject) => {
            if (!resource.id) {
              return reject(new Error('Invalid data.'));
            }

            router.replace(
              {
                name: `${routeName ? routeName + '-' : ''}edit-resource`,
                params: {
                  slug: createSlug(resource.title),
                  id: String(resource.id),
                },
              },
              () => resolve(resource),
              (error: any) => reject(error),
            );
          });
        }

        return Promise.resolve(resource);
      })
      .then((resource) => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: successMsg,
            visit: {
              link: {
                name: `${routeName ? routeName + '-' : ''}resource`,
                params: {
                  id: resource.id,
                },
              },
              label: i18next.t('Visit resource'),
            },
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ResourcesMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [ResourcesActions.DELETE_EDITABLE]({ state, dispatch, commit }): Promise<void> {
    commit(ResourcesMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

    const routeName = (router.currentRoute.name == 'clinical-support-edit-resource' ) ? 'clinical-support' : 'resources';

    return new Promise((resolve, reject) => {
      if (!state.editable) {
        return reject(new Error('Invalid data!'));
      }

      resolve(api.assets.remove(state.editable.id));
    })
      .then(() => {
        commit(ResourcesMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);

        return new Promise<void>((resolve, reject) => {
          router.push(
            {
              name: routeName,
            },
            () => resolve(),
            (error: any) => reject(error),
          );
        });
      })
      .then(() => {
        commit(ResourcesMutations.SET_EDITABLE, undefined);

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The resource was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ResourcesMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [ResourcesActions.REGISTER_RESOURCE_VIEW]({ state, commit, rootGetters }): Promise<void> {
    if (!state.currentResource) {
      return Promise.resolve();
    }

    const userId = rootGetters['user/profile/userId'];
    const key = `${RECENTLY_VIEWED_STORAGE_KEY_PREFIX}_${userId}`;
    const resourceId = state.currentResource.id;
    const recentlyViewed = [
      resourceId,
      // Append recently viewed without current resource ID
      ...state.recentlyViewed.filter((val) => val !== resourceId),
    ];

    commit(ResourcesMutations.SET_RECENTLY_VIEWED, recentlyViewed);
    localStorage.setItem(key, JSON.stringify(recentlyViewed));

    return Promise.resolve();
  },

  [ResourcesActions.LOAD_LOCAL_RECENTLY_VIEWED_RESOURCES]({ commit, rootGetters }): Promise<void> {
    const userId = rootGetters['user/profile/userId'];

    let recentlyViewed: number[] = [];
    const key = `${RECENTLY_VIEWED_STORAGE_KEY_PREFIX}_${userId}`;
    const local = localStorage.getItem(key);

    if (local) {
      try {
        recentlyViewed = Array.from(JSON.parse(local)).map((val) => Number(val));
      } catch (e) {
        localStorage.removeItem(key);
      }
    }

    recentlyViewed = recentlyViewed.filter((val, index, arr) => !!val && arr.indexOf(val) === index);

    commit(ResourcesMutations.SET_RECENTLY_VIEWED, recentlyViewed);
    localStorage.setItem(key, JSON.stringify(recentlyViewed));

    return Promise.resolve();
  },

  [ResourcesActions.LOAD_RECENTLY_VIEWED_RESOURCES]({ state, commit, dispatch }): Promise<void> {
    commit(ResourcesMutations.RECENTLY_VIEWED_RESOURCES_LOADING);

    if (!state.recentlyViewed.length) {
      commit(ResourcesMutations.RECENTLY_VIEWED_RESOURCES_LOADED, []);

      return Promise.resolve();
    }

    return Promise.all(state.recentlyViewed.map((resourceId) => api.assets.get(resourceId)))
      .then((responses) => {
        const resources = responses.map((res) => Resource.fromJSON(res.data));

        commit(
          ResourcesMutations.RECENTLY_VIEWED_RESOURCES_LOADED,
          resources.sort((resourceA, resourceB) => {
            return state.recentlyViewed.indexOf(resourceA.id) - state.recentlyViewed.indexOf(resourceB.id);
          }),
        );
      })
      .catch((error) => {
        commit(ResourcesMutations.RECENTLY_VIEWED_RESOURCES_FAILED, error);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [ResourcesActions.LOAD_RELATED]({ commit, dispatch }, { id }): Promise<void> {
    commit(ResourcesMutations.RELATED_LOADING);

    return api.assets
      .listRelated(id)
      .then((response) => {
        commit(
          ResourcesMutations.SET_RELATED_RESOURCE,
          response.data.data.map((item) => Resource.fromJSON(item)),
        );
        commit(ResourcesMutations.RELATED_LOADED);
      })
      .catch((error) => {
        return dispatch(ResourcesActions.RAISE_RELATED_ERROR, error);
      });
  },

  [ResourcesActions.PERSONALIZE]({ state, commit, dispatch }): Promise<void> {
    if (!state.currentResource) {
      return Promise.resolve();
    }
    commit(ResourcesMutations.PERSONALIZE_LOADING);
    const id = state.currentResource.id;
    return api.assets
      .personalize(id)
      .then((response) => {
        window.open(response.data.url, '_blank');
        commit(ResourcesMutations.PERSONALIZE_LOADED);
      })
      .catch((error) => {
        return dispatch(ResourcesActions.RAISE_PERSONALIZE_ERROR, error);
      });
  },

  /**
   * ERRORS
   */
  [ResourcesActions.RAISE_LIST_ERROR]({ commit, dispatch }, error): Promise<void> {
    commit(ResourcesMutations.LIST_LOADING_FAILED, error);
    return dispatch(RootActions.ERROR, error, { root: true });
  },
  [ResourcesActions.RAISE_CURRENT_ERROR]({ commit, dispatch }, error): Promise<void> {
    commit(ResourcesMutations.CURRENT_LOADING_FAILED, error);
    return dispatch(RootActions.ERROR, error, { root: true });
  },
  [ResourcesActions.RAISE_RELATED_ERROR]({ commit, dispatch }, error): Promise<void> {
    commit(ResourcesMutations.RELATED_LOADING_FAILED, error);
    return dispatch(RootActions.ERROR, error, { root: true });
  },
  [ResourcesActions.RAISE_PERSONALIZE_ERROR]({ commit, dispatch }, error): Promise<void> {
    commit(ResourcesMutations.PERSONALIZE_LOADING_FAILED, error);
    return dispatch(RootActions.ERROR, error, { root: true });
  },

  [ResourcesActions.BULK_RESTORE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(ResourcesMutations.LIST_LOADING);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.assets.restore(id))))
      .then(() => {
        dispatch(ResourcesActions.LOAD, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% resource was restored successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ResourcesMutations.LIST_LOADING_FAILED);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ResourcesActions.BULK_DELETE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(ResourcesMutations.LIST_LOADING);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.assets.remove(id))))
      .then(() => {
        dispatch(ResourcesActions.LOAD, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% resource was deleted successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ResourcesMutations.LIST_LOADING_FAILED);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  async [ResourcesActions.LOAD_LASER_CATEGORY_ID]({ commit }): Promise<void> {
    commit(ResourcesMutations.SET_LASER_CATEGORY_ID, undefined);

    const response = await api.categories.getLaserCategory();

    if (!response.data || !response.data.id) {
      return;
    }

    commit(ResourcesMutations.SET_LASER_CATEGORY_ID, response.data.id);
  },
  async [ResourcesActions.LOAD_LASER_HIGHLIGHTED_RESOURCES](
    { state, commit, dispatch },
    { country_id, language_code } = {},
  ): Promise<void> {
    try {
      commit(ResourcesMutations.SET_LASER_HIGHLIGHTED_STATUS, ListStatus.Loading);

      if (!state.laserCategoryId) {
        await dispatch(ResourcesActions.LOAD_LASER_CATEGORY_ID);
      }

      if (!state.laserCategoryId) {
        throw new Error('Category "Laser Safety & Laser Science" doesn\'t exist.');
      }

      const response = await api.assets.list({
        categories: [state.laserCategoryId],
        country_id,
        language_code,
      });
      commit(
        ResourcesMutations.SET_LASER_HIGHLIGHTED_RESOURCES,
        response.data.data.map((item) => Resource.fromJSON(item)),
      );
      commit(ResourcesMutations.SET_LASER_HIGHLIGHTED_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
      commit(ResourcesMutations.LIST_LOADING_FAILED);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [ResourcesActions.LOAD_TRAINING_RESOURCES](
    { commit, dispatch },
    filters: ResourcesRequestFilters = {},
  ): Promise<void> {
    commit(ResourcesMutations.SET_TRAINING_RESOURCES_STATUS, ResourcesStatus.Loading);

    return api.assets
      .listTrainingResources({ limit: 3 })
      .then((response) => {
        commit(
          ResourcesMutations.SET_TRAINING_RESOURCES,
          response.data.map((item) => Resource.fromJSON(item)),
        );
        commit(ResourcesMutations.SET_TRAINING_RESOURCES_STATUS, ResourcesStatus.Loaded);
      })
      .catch((error) => {
        commit(ResourcesMutations.SET_TRAINING_RESOURCES_STATUS, ResourcesStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  /**
   * Resource trainings
   */
  [ResourcesActions.COMPLETE_ASSET_TRAINING](
    { state, commit },
    { assetType, assetId }: { assetType: 'file' | 'link'; assetId: number },
  ): Promise<void> {
    if (!state.currentResource) {
      return Promise.resolve();
    }

    commit(ResourcesMutations.SET_COMPLETE_ASSET_TRAINING_STATUS, ListStatus.Loading);

    return api.assets
      .completeTraining({ resourceId: state.currentResource.id, assetType, assetId })
      .then(() => {
        commit(ResourcesMutations.SET_COMPLETE_ASSET_TRAINING, {
          assetType,
          assetId,
          value: true,
        });
        commit(ResourcesMutations.SET_COMPLETE_ASSET_TRAINING_STATUS, ListStatus.Loaded);
      })
      .catch(() => {
        commit(ResourcesMutations.SET_COMPLETE_ASSET_TRAINING_STATUS, ListStatus.Failed);
      });
  },
};

export default actions;


import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import { formatNumber } from '@/i18n';

import User, { UserStatus, UserStatuses } from '@/store/models/user';
import { UsersActions } from '@/store/modules/users/types';

import tableViewMixin from '@/components/mixins/table-view';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import UsersFilter from '@/components/forms/users-filter.vue';
import { PracticeStatus } from '@/store/modules/practices/types';
import CheckboxControl from '@/components/controls/checkbox.vue';
import { UserActions } from '@/store/modules/user/types';
import { UserRole } from '@/store/modules/user/profile/types';
import CountryName from '@/components/blocks/country-name.vue';

export default mixins(tableViewMixin).extend({
  name: 'UsersView',
  components: {
    NotificationBanner,
    TableListSkeleton,
    Dropdown,
    PaginationControl,
    UsersFilter,
    CheckboxControl,
    CountryName,
  },
  data() {
    return {
      selectedItems: [] as number[],
    };
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin', 'isImpersonating']),
    ...mapState('users', {
      users: 'list',
      pagination: 'listPagination',
    }),
    ...mapGetters('users', {
      isLoading: 'listIsLoading',
      isLoaded: 'listIsLoaded',
      isEmpty: 'listIsEmpty',
    }),
    areAllItemsSelected(): boolean {
      return this.selectedItems.length === this.users.length;
    },
  },
  methods: {
    formatNumber,
    ...mapActions('users', {
      restoreAction: UsersActions.RESTORE,
    }),
    ...mapActions('user', {
      impersonateAction: UserActions.IMPERSONATE,
    }),
    userStatus(selectedUser: User) {
      if (
        selectedUser.status === UserStatus.Inactive &&
        selectedUser.practice &&
        selectedUser.practice.status === PracticeStatus.Inactive
      ) {
        return this.$t('Practice Deleted');
      }

      return UserStatuses[selectedUser.status];
    },

    dropdownItems(selectedUser: User) {
      const items: DropdownItems = {};
      if (this.isSuperAdmin && !this.isImpersonating && selectedUser.role !== UserRole.SuperAdmin) {
        items.impersonate = {
          label: this.$t('View AMPS as') + ' ' + selectedUser.fullName,
          icon: 'user-load',
        };
      }

      items.edit = {
        label: this.$t('Edit'),
        icon: 'pencil',
      };

      if (selectedUser.status === UserStatus.Inactive) {
        items.restore = {
          label: this.$t('Restore'),
          icon: 'restore',
        };
      }

      return items;
    },

    handleClick(selectedUser: User, action: string) {
      if ('edit' === action) {
        this.$router.push({
          name: 'edit-user',
          params: { id: String(selectedUser.id) },
        });
      }

      if ('impersonate' === action) {
        this.impersonateAction({ id: selectedUser.id });
      }

      if ('restore' === action) {
        const answer = window.confirm(this.$t('Do you really want to restore this user?'));

        if (answer) {
          this.restoreAction({ id: selectedUser.id });
        }
      }
    },

    isSelected(item: User): boolean {
      return this.selectedItems.indexOf(item.id) !== -1;
    },

    selectItem(item: User) {
      if (this.isSelected(item)) {
        this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem !== item.id);
        return;
      }

      this.selectedItems.push(item.id);
    },

    selectAll() {
      if (this.areAllItemsSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.users.map((item: User) => item.id);
      }
    },
  },
});
